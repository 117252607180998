<template>
  <div class="max-w-[1240px] mx-auto" v-if="products">
    <div class="mt-4 mx-5 sm:mx-0" v-if="products?.bannerProducts">
      <swiper :slides-per-view="1" :space-between="50" :modules="modules" navigation autoplay @swiper="onSwiper" @slideChange="onSlideChange">
        <!-- <swiper-slide><img class="rounded-xl object-fit w-full h-[532px]" :src="require('../assets/product/banner-1.png')" alt="" /></swiper-slide> -->
        <swiper-slide v-for="(item, index) in products?.bannerProducts" :key="index">
          <a :href="`${item?.link}`">
            <img class="sm:rounded-xl object-fit w-full md:h-[532px]" :src="item?.image_url" alt="" />
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <div>
      <div v-if="isLoading" class="flex justify-center items-center h-[calc(70vh-64px)]">
        <!-- <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div> -->
      </div>
      <transition name="fade" mode="out-in">
        <div class="mx-5 lg:mx-0 py-4" v-if="products?.popularProducts?.length > 0">
          <div class="flex items-center pb-4">
            <span class="text-slate-800 text-lg font-semibold mr-4">Хамгийн эрэлттэй</span>
            <a href="/viewall/popular" class="underline text-sm text-slate-500 cursor-pointer">бүгдийг үзэх</a>
          </div>
          <div>
            <swiper-comp
              :product="products?.popularProducts || []"
              :isLoggedIn="isLoggedIn"
              :modules="modules"
              :loop="true"
              :onSwiper="onSwiper"
              :breakpoints="breakpoints"
              :isLoading="isLoading"
            ></swiper-comp>
          </div>
        </div>
      </transition>
    </div>
    <div>
      <div v-if="isLoading" class="flex justify-center items-center h-[calc(70vh-64px)]">
        <!-- <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div> -->
      </div>
      <transition name="fade" mode="out-in">
        <div v-if="products?.categories?.length > 0">
          <div class="mx-5 lg:mx-0 py-4" v-for="(category, index) in products?.categories" :key="index">
            <div class="flex items-center pb-4">
              <span class="text-slate-800 text-lg font-semibold mr-4">{{ category?.name }}</span>
              <a :href="`/viewall/${category.id}`" class="underline text-sm text-slate-500 cursor-pointer">бүгдийг үзэх</a>
            </div>
            <div>
              <swiper-comp
                :product="category?.products || []"
                :isLoggedIn="isLoggedIn"
                :modules="modules"
                :loop="true"
                :onSwiper="onSwiper"
                :breakpoints="breakpoints"
              ></swiper-comp>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <back-to-top></back-to-top>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

// import axios from "axios";

import SwiperComp from "@/components/product/SwiperComp.vue";
import BackToTop from "@/components/ui/BackToTop.vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

export default {
  name: "HomePage",

  components: {
    Swiper,
    SwiperSlide,
    SwiperComp,
    BackToTop,
  },

  async setup() {
    const store = useStore();
    const products = ref([]);
    const isLoading = ref(true);
    const isLoggedIn = ref(false);

    onMounted(async () => {
      checkLoggedIn();
      fetchHomePageProducts();
      if (Cookies.get("BStoken")) {
        if (JSON.parse(localStorage.getItem("backetList"))) {
          try {
            JSON.parse(localStorage.getItem("backetList")).map(async (item) => {
              item.userId = jwt_decode(Cookies.get("BStoken")).user_id;
              const res = await store.dispatch("products/insertUpdateDeleteBacket", item);
              if (res.status == 200) {
                store.commit("products/setBacketListCount", res.data.backetListCount);
                localStorage.removeItem("backetList");
              }
            });
          } catch (e) {
            console.error(e);
            return e;
          }
        } else if (localStorage.getItem("wishList")) {
          try {
            JSON.parse(localStorage.getItem("wishList")).map(async (item) => {
              item.userId = jwt_decode(Cookies.get("BStoken")).user_id;
              const res = await store.dispatch("products/addToWishList", item);
              if (res.status == 200) {
                res.data > 0 ? store.commit("products/setWishListCount", res.data) : 0;
                localStorage.removeItem("wishList");
              }
            });
          } catch (e) {
            console.error(e);
            return e;
          }
        }
      }
    });

    const checkLoggedIn = () => {
      const user = Cookies.get("BStoken");
      if (user) {
        if (!jwt_decode(user).user_id) isLoggedIn.value = false;
        isLoggedIn.value = true;
      }
    };

    const fetchHomePageProducts = async () => {
      try {
        if (isLoggedIn.value) await store.dispatch("products/fetchHomePageProducts", Cookies.get("BStoken"));
        else await store.dispatch("products/fetchHomePageProducts");

        products.value = await store.getters["products/getHomePageProducts"];

        isLoading.value = false;
      } catch (e) {
        console.error(e);
        return e;
      } finally {
        isLoading.value = false;
      }
    };

    const onSwiper = (swiper) => {
      swiper.params.loop = true;
      swiper.params.autoplay = {
        delay: 3000,
      };
    };

    const onSlideChange = () => {};

    const breakpoints = {
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 10,
      },
    };

    return {
      isLoading,
      isLoggedIn,
      products,
      onSwiper,
      onSlideChange,
      breakpoints,
      modules: [Navigation, Autoplay],
      // kakaoAddress,
    };
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
