<template>
  <div class="App bg-bg1 notranslate">
    <mobile-header v-show="showHeaderFooter" @toggle-side-menu="toggleSideMenu"></mobile-header>
    <desktop-header v-show="showHeaderFooter"></desktop-header>
    <desktop-navbar v-show="showHeaderFooter" @toggle-side-menu="toggleLgSideMenu"></desktop-navbar>
    <sm-screen-menu v-show="isVisible" @toggle-side-menu="toggleSideMenu"></sm-screen-menu>
    <lg-screen-menu v-show="lgScreenMenu" @toggle-side-menu="toggleLgSideMenu"></lg-screen-menu>
    <div
      v-show="isVisible || lgScreenMenu"
      @click="
        isVisible = false;
        lgScreenMenu = false;
      "
      class="backdrop"
    ></div>
    <suspense>
      <router-view></router-view>
    </suspense>
    <footer-page v-if="showHeaderFooter"></footer-page>
  </div>
</template>

<script>
import { Suspense, ref, watch } from "vue";
import { useRoute } from "vue-router";
import MobileHeader from "./components/ui/MobileHeader.vue";
import DesktopHeader from "./components/ui/DesktopHeader.vue";
import DesktopNavbar from "./components/ui/DesktopNavbar.vue";
import SmScreenMenu from "./components/ui/SmScreenMenu.vue";
import LgScreenMenu from "./components/ui/LgScreenMenu.vue";
import FooterPage from "./components/ui/FooterPage.vue";
export default {
  name: "App",
  components: {
    MobileHeader,
    DesktopHeader,
    DesktopNavbar,
    SmScreenMenu,
    LgScreenMenu,
    FooterPage,
    Suspense,
  },
  setup() {
    const route = useRoute();
    const isVisible = ref(false);
    const lgScreenMenu = ref(false);
    const showHeaderFooter = ref(true);

    const toggleSideMenu = () => {
      isVisible.value = !isVisible.value;
    };

    const toggleLgSideMenu = () => {
      lgScreenMenu.value = !lgScreenMenu.value;
    };

    watch(
      () => route.path,
      (newRouteName) => {
        if (newRouteName.includes("admin")) {
          showHeaderFooter.value = false;
        } else {
          showHeaderFooter.value = true;
        }
      }
    );

    const backToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    return {
      isVisible,
      lgScreenMenu,
      backToTop,
      toggleSideMenu,
      toggleLgSideMenu,
      showHeaderFooter,
    };
  },
};
</script>

<style>
.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
  /* display: none; */
}
</style>
