<template>
  <button @click="backToTop" class="sm:w-[300px] hidden md:block">
    <div class="fixed bottom-4 right-4 z-50 w-16 h-16 bg-slate-300 rounded-full flex justify-center items-center mr-4 mb-4">
      <img class="h-10" src="../../assets/common/chevron-up.svg" alt="" />
    </div>
  </button>
</template>

<script>
export default {
  setup() {
    const backToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    return {
      backToTop,
    };
  },
};
</script>
