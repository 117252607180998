<template>
  <div class="hidden md:block px-4 bg-white">
    <div class="max-w-[1240px] mx-auto">
      <div class="flex justify-between items-center py-4">
        <div>
          <a href="/"> <img class="w-32 h-auto;" :src="require('../../assets/common/logo123.jpg')" alt="" /> </a>
        </div>
        <div class="w-3/5 flex justify-center">
          <input
            type="text"
            placeholder="Бүтээгдэхүүн хайх..."
            class="border-1 rounded-r-full rounded-l-full border-gray-300 px-8 py-3 w-full focus:outline-none focus:ring-2 focus:ring-gray-300 text-sm"
            v-model="search"
            @keyup.enter="handleSearch"
          />
          <button class="-ml-14 w-6" @click="handleSearch">
            <img :src="require('../../assets/common/search.svg')" alt="" />
          </button>
        </div>
        <div class="flex gap-4 justify-center items-center">
          <div class="relative">
            <a class="flex flex-col justify-center items-center" href="/wishlist">
              <img :src="require('../../assets/common/heart-black.svg')" alt="" />
              <!-- <p class="text-xs">Хүсэл</p> -->
              <p class="text-xs text-sky-500">WishList</p>
            </a>
            <div
              v-if="wishlistcount > 0"
              class="absolute -top-1 -right-1 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center text-white text-[12px]"
            >
              <span>{{ wishlistcount }}</span>
            </div>
          </div>
          <div class="hover: cursor-pointer">
            <a class="flex flex-col justify-center items-center" v-if="showUser" href="/user/profile">
              <img :src="require('../../assets/common/user.svg')" alt="" />
              <p v-if="userName" class="text-xs capitalize text-sky-500">{{ userName }}</p>
              <p v-else class="text-xs">Нэвтэрсэн</p>
            </a>

            <a class="flex flex-col justify-center items-center" v-if="!showUser" href="/login">
              <img :src="require('../../assets/common/user.svg')" alt="" />
              <!-- <p class="text-xs">Нэвтрэх</p> -->
              <p class="text-xs text-sky-500">Login/Register</p>
            </a>
          </div>

          <div class="relative">
            <a class="flex flex-col justify-center items-center" href="/cart">
              <img :src="require('../../assets/common/shopping-cart.svg')" alt="" />
              <!-- <p class="text-xs">Сагс</p> -->
              <p class="text-xs text-sky-500">Cart</p>
            </a>
            <div
              v-if="backetlistcount > 0"
              class="absolute -top-1 -right-1 bg-red-500 rounded-full w-4 h-4 flex justify-center items-center text-white text-[12px]"
            >
              <span>{{ backetlistcount }}</span>
            </div>
          </div>

          <div @click="logOut" v-if="userToken">
            <div class="flex flex-col justify-center items-center">
              <img :src="require('../../assets/common/signoutSvg.svg')" alt="" class="mt-[4px]" />
              <p class="text-xs text-sky-500 mt-[5px]">Log out</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export default {
  name: "DesktopHeader",
  setup() {
    const store = useStore();
    const router = useRouter();
    const wishlistcount = ref(0);
    const backetlistcount = ref(0);
    const showUser = ref(false);
    const userName = ref("");
    const search = ref("");

    const userToken = ref("");

    onMounted(async () => {
      try {
        const token = Cookies.get("BStoken");
        userToken.value = token;

        if (token) {
          showUser.value = true;
          const userId = jwt_decode(token).user_id;

          // Fetch wishlist data and user accounts
          await Promise.all([
            store.dispatch("products/getWishListData", userId),
            store.dispatch("products/getBacketData", userId),
            store.dispatch("login/fetchAccount", userId),
          ]);

          backetlistcount.value = store.getters["products/getBacketData"]?.length;
          wishlistcount.value = store.getters["products/getWishList"]?.length;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });

    const handleSearch = async () => {
      if (!search.value) return;
      router.push({
        name: "Search",
        params: {
          search: search.value,
        },
      });
    };

    //wishList
    wishlistcount.value = computed(() => {
      return store.getters["products/getWishListCount"];
    }).value;

    watch(
      () => store.getters["products/getWishListCount"],
      (newVal) => {
        wishlistcount.value = newVal;
      }
    );

    //backet
    backetlistcount.value = computed(() => {
      return store.getters["products/getBacketCount"];
    }).value;

    watch(
      () => store.getters["products/getBacketCount"],
      (newVal) => {
        backetlistcount.value = newVal;
      }
    );

    //account
    watch(
      () => store.getters["login/getAccounts"],
      (newVal) => {
        userName.value = newVal?.data[0]?.first_name;
      }
    );

    //logout
    const logOut = () => {
      Cookies.remove("BStoken");
      window.location.href = "/";
    };

    return {
      wishlistcount,
      backetlistcount,
      showUser,
      userName,
      search,
      handleSearch,
      logOut,
      userToken,
    };
  },
};
</script>
